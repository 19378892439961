import { IAdaptiveContentService } from './types';
import {
  GetFunctionExternalIdResult,
  IAdaptiveContentAdapter,
} from '../../adapter/adaptiveContent/types';

class AdaptiveContentService implements IAdaptiveContentService {
  constructor(private readonly adaptiveContentAPI: IAdaptiveContentAdapter) {}

  async getFunctionExternalId(
    compId: string,
  ): Promise<GetFunctionExternalIdResult> {
    return this.adaptiveContentAPI.getFunctionExternalId(compId);
  }

  async triggerFunctionCleanup(): Promise<void> {
    return this.adaptiveContentAPI.triggerFunctionsCleanup();
  }

  async getAdaptiveContent(
    compId: string,
    originalContent: string,
    timeout?: number,
  ): Promise<string> {
    return this.adaptiveContentAPI.getAdaptiveContent(
      compId,
      originalContent,
      timeout,
    );
  }
}

export const buildAdaptiveContentService = (
  adaptiveContentAPI: IAdaptiveContentAdapter,
): IAdaptiveContentService => new AdaptiveContentService(adaptiveContentAPI);
