import { buildAdaptiveContentAdapter } from '../../adapter/adaptiveContent/adaptiveContent.adapter';
import { ONE_SECOND_IN_MS } from '../../constants';
import { buildAdaptiveContentService } from '../../services/adaptiveContent/adaptiveContent.service';
import model from './model';

export default model.createController(
  ({ $bind, $widget, flowAPI, controllerConfig, $w, widgetConfig, $props }) => {
    return {
      pageReady: async () => {
        const { httpClient } = flowAPI;
        // Previously we retrieved compId from $props, but encountered issues
        // (see discussion: https://wix.slack.com/archives/C08GFAH37QS/p1741600905962749?thread_ts=1741539358.601889&cid=C08GFAH37QS)
        // Since the suggested solution didn't work, we're using this alternative approach
        // @ts-expect-error
        const compId = $w('#text1').uniqueId.split('_')[0];
        const adaptiveContentAdapter = buildAdaptiveContentAdapter(httpClient);
        const adaptiveContentService = buildAdaptiveContentService(
          adaptiveContentAdapter,
        );

        if (flowAPI.environment.isSSR || flowAPI.environment.isPreview) {
          const originalContent = $w('#text1').text;

          $w('#text1').text = await adaptiveContentService.getAdaptiveContent(
            compId,
            originalContent,
            flowAPI.environment.isSSR
              ? ONE_SECOND_IN_MS
              : ONE_SECOND_IN_MS * 10,
          );
        }
      },
      exports: {},
    };
  },
);
